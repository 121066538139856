body {
  font-family: "NanumSquare", "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-size: 14px;
}
.dvh-100 {
  height: 100dvh !important;
}

[data-theme-version="light"] #main-wrapper {
  background-color: #f8fbfd;
}

.logo img {
  height: 70px !important;
}
@media (max-width: 576px) {
  .logo img {
    height: 50px !important;
  }
}

@media (min-width: 1900px) {
  .col-xxl-8 {
      flex: 0 0 auto;
      width: 66.66667%;
      max-width: calc(1240px + calc(var(--bs-gutter-x)* .5) * 2);
  }
}

.logo-rankify-icon {
  height: 40px;
  object-fit: contain;
}
.logo-rankify-text {
  height: 40px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}
@media (max-width: 35.9375rem) {
  img.logo-rankify-icon {
    height: 30px !important;
    width: auto !important;
    max-width: 100% !important;
  }
}
@media (max-width: 720px) {
  .hamburger.is-active .line:first-child {
    transform: translateY(2px) translateX(0px) rotate(-45deg);
  }
  .hamburger.is-active .line:nth-child(3) {
    transform: translateY(-2px) translateX(0px) rotate(-135deg);
  }
}

input[type="password"] {
  font-family: "Open Sans", sans-serif;
}

.color-scrollbar-os {
  scrollbar-color: rgba(111, 133, 147, 0.6) #fff0;
  &::-webkit-scrollbar {
    opacity: 1 !important;
  }
}

.hidden-scrollbar-os {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px; /* Set width of the scrollbar */
    height: 0px;
    background-color: transparent;
  }
}
table.sticky-table-ranking-keyword > thead > tr > th {
  height: 54px;
}

// table.sticky-table-ranking-keyword > tbody > tr > td, table.sticky-table-ranking-keyword > thead > tr > th.th-hidden{
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

  .btn-collapse-header-table{
    position: absolute;
    top: -26px;
    border: 0px solid;
    font-size: 16px;
    z-index: 3;
    background-color: #c8c8c8;
    cursor: pointer;
    // display: none;
  }


table.sticky-table-ranking-keyword {
  width: 100%;
  table-layout: fixed;
}

table.sticky-table-ranking-keyword > thead > tr > th:nth-child(2),
table.sticky-table-ranking-keyword > tbody > tr > td:nth-child(2) {
  position: sticky;
  z-index: 0;
  left: 0;
}

table.sticky-table-ranking-keyword.is-scroll:not(.table-fixed-header)
  > thead
  > tr
  > th:nth-child(2) {
  background-color: #f8f9fb !important;
}
table.sticky-table-ranking-keyword.is-scroll:not(.table-fixed-header)
  > tbody
  > tr
  > td:nth-child(2) {
  background-color: var(--card) !important;
}

table.sticky-table-ranking-keyword.is-scroll > tbody > tr > td:nth-child(2),
table.sticky-table-ranking-keyword.is-scroll > thead > tr > th:nth-child(2) {
  z-index: 2;
}

table.sticky-table-ranking-keyword.is-scroll
  > tbody
  > tr
  > td:nth-child(2)::before,
table.sticky-table-ranking-keyword.is-scroll
  > thead
  > tr
  > th:nth-child(2)::before {
  position: absolute;
  content: "";
  width: 5px;
  height: calc(100% + 2px);
  background-color: transparent;
  z-index: 5;
  right: 0px;
  top: -1px;
  bottom: -1px;
  box-shadow: rgba(0, 0, 0, 0.21) 4px 0px 6px -2px;
}

.table-ranking-keyword {
  transition: all 0.3s ease;
  td {
    color: var(--text-dark);
    padding: 0.5rem 0.5rem !important;
    &.box-sub-row-table {
      background-color: rgba(234, 234, 234, 0.4) !important;
      padding: 30px 36px !important;
      .table thead tr th:last-child {
        text-align: center !important;
      }
    }
  }
  tr:hover {
    background-color: rgba(250, 250, 250, 1) !important;
  }
  &.table-bordered > :not(caption) > * {
      border-color: var(--border);
  }
  th.collapse{
    border-radius: 0;
  }
}
[data-theme-version="dark"] .table-ranking-keyword {
  tr:hover {
    background-color: rgba(250, 250, 250, 0.1) !important;
  }
}

table.dataTable.table-fixed-header {
  & thead th {
    border-bottom: 0px solid var(--body-bg);
    background-color: #f8f9fb;
  }
  & thead tr {
    border-bottom-color: var(--border);
  }
}

.table.table-detail-keyword thead {
  & th {
    border-bottom: 0px solid var(--body-bg);
    background-color: #f8f9fb;
  }
  & tr th:last-child {
    text-align: center !important;
  }
}
.infinite-scroll-component {
  overflow: visible !important;
  &::-webkit-scrollbar {
    width: 0px; /* Set width of the scrollbar */
    height: 0px;
    background-color: transparent;
  }
}
.col-ranking-category {
  min-width: calc(100dvw / 4);
  @media (max-width: 720px) {
    & {
      min-width: auto;
    }
  }
}
.border-card-table{
  position: relative;
  &.border-bottom{
    border-bottom: var(--bs-border-width) solid var(--border);
  }
  &::before {
      position: absolute;
      content: "";
      width: var(--bs-border-width);
      z-index: 5;
      left: 0px;
      top: 0px;
      bottom: 0px;
      background-color: var(--border);
      
    }
    &::after {
      position: absolute;
      content: "";
      width: var(--bs-border-width);
      z-index: 5;
      right: 0px;
      top: 0px;
      bottom: 0px;
      background-color: var(--border);
    }
}
.table.table-bordered.table-bordered-none-border-header{
  border: 0px solid var(--border);
  & thead {
    border: 0px solid var(--border);
    & tr{
      border: 0px solid var(--border);
      & > th:first-child{
        border: 0px solid var(--border);
      }
      & > th:last-child{
        border: 0px solid var(--border);
      }
    }
  }
}
#back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: all 0.5s ease;
  display: none;
  z-index: 1000;
  width: 56px;
  height: 56px;
  border-radius: 28px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  // &:hover {
  //     animation: fade-up 1.5s infinite linear;
  // }
  & svg {
    width: 35px;
    height: 35px;
  }
  @media (max-width: 720px) {
    & {
      bottom: 20px;
      width: 50px;
      height: 50px;
      border-radius: 25px;
    }
    & svg {
      width: 32px;
      height: 32px;
    }
  }
  @media (max-width: 650px) {
    & {
      bottom: 20px;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
    & svg {
      width: 30px;
      height: 30px;
    }
  }
}

@keyframes fade-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

// Begin::Form Filter Category Page
.nav-link-custom-select {
  border: 1px solid var(--border);
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
  // min-width: 300px;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  &:hover {
    // border-color: var(--primary)
  }
  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.813rem;
    height: 2.5rem;
    line-height: 20px;
    border-radius: 0;
    padding: 5px;
    &.active {
      background-color: var(--point);
      border-radius: 0;
      color: white !important;
    }
  }
}
[data-theme-version="dark"] .nav-pills .nav-link {
  color: #fff;
}

.cover-photo-introduction {
  background-size: cover;
  background-position: center;
  min-height: 12.5rem;
  width: 100%;
  background-image: url(../images/introduction/cover.jpg);
}

.image-introduction {
  background-color: rgba(234, 234, 234, 0.49);
  border-radius: 5px;
  width: 100%;
  height: 168px;
  object-fit: cover;
  object-position: center;
}
.metismenu {
  & > li > a {
    border-radius: 10px;
  }
  ul a {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    font-size: 0.875rem !important;
    &:hover {
      background-color: rgba(234, 234, 234, 0.5);
      border-radius: 10px;
    }
  }
  & > li:hover > a {
    background-color: rgba(234, 234, 234, 0.5);
    border-radius: 10px;
  }
  &.metismenu-bottom:not(.mm-active) > li:hover > a {
    background-color: rgba(246, 180, 12, 0.2);
  }
  .mm-active {
    .icon-item-menu-fill {
      path {
        fill: #f6b40c;
      }
    }
    .icon-item-menu-stroke {
      path {
        stroke: #f6b40c;
      }
    }
    .icon-item-menu-stroke-rect {
      path,
      rect,
      clipPath,
      g,
      defs,
      circle {
        stroke: #f6b40c;
      }
    }
  }
}

.btn-login-on-header {
  max-height: 36px;
  font-size: 14px !important;
  // flex: 1 0 auto;
  // display: grid;
  // align-items: center;
  // align-content: center;
  // padding: 11px 20px;
  // line-height: 20px;
  border-width: 1px;
  @media (max-width: 576px) {
    max-height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px !important;
  }
}

.btn-warning-custom {
  color: #e7a600;
  background: rgba(255, 247, 219, 1);
  &:hover {
    color: #e7a600;
    background: rgba(255, 247, 219, 0.8);
  }
}
.btn-warning-charge {
  color: #fff;
  background: rgb(246, 180, 12);
  &:hover {
    color: #fff;
    background: rgba(246, 180, 12, 0.8);
  }
}
.text-color-1 {
  color: #6c6f76;
}
.text-color-2 {
  color: #47494e;
}
.text-color-3 {
  color: #868990;
}
.text-color-4 {
  color: #e9ebf1;
}
.text-color-5 {
  color: #a7abb5;
}
.bg-purple-light {
  background-color: #f6f7fa;
}
[data-theme-version="dark"] {
  .text-color-1,
  .text-color-2,
  .text-color-3,
  .text-color-4,
  .text-color-5 {
    color: #fff;
  }

  .bg-purple-light {
    background-color: rgba(246, 247, 250, 0.3);
  }
}
.form-control-popper-date-picker-range {
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker {
    font-family: "NanumSquare", "Open Sans", sans-serif;
    border-radius: 8px;
    // background: #FFF;
    box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.2);
    border-width: 0px;
  }
  .react-datepicker__day--selected {
    border-radius: 8px;
    background: #1a1a1c;
  }
  .react-datepicker__week-number--selected {
    border-radius: 8px;
    background-color: #f6b40c;
    color: #fff;
  }
  .react-datepicker__week {
    &:has(.react-datepicker__week-number):has(.react-datepicker__day) {
      border: 1px dashed transparent;
      border-radius: 10px;
      transition: all 0.3s ease;
    }
    &:has(.react-datepicker__week-number):has(.react-datepicker__day:hover),
    &:has(.react-datepicker__week-number--selected) {
      background-color: #fff7db;
      border-color: #f6b40c;
    }
  }
  .react-datepicker__day--keyboard-selected {
    background-color: #fff7db;
    border-radius: 8px;
  }
  .react-datepicker__month-text--keyboard-selected {
    border-radius: 8px;
    background: #1a1a1c;
    color: #fff;
  }
  .react-datepicker__day:hover {
    border-radius: 8px;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-weight: 600;
  }
  .react-datepicker__header {
    border-bottom: 1px solid #f3f3f3;
  }
  .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    border-radius: 2px;
  }
  .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
    min-width: 5rem;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .react-datepicker__month-container:has(.react-datepicker__monthPicker) {
    min-width: 350px;
  }
  &.picker-2-column{
    .react-datepicker{
      min-width: 485px;
      &:has(.react-datepicker__week-number){
        min-width: 550px;
      }
      &:has(.react-datepicker-year-header){
        min-width: 290px;
      }
      .react-datepicker__day--in-range{
       background-color: rgba(246, 180, 12, 0.7);
      }
      .react-datepicker__month-text--in-range{
        background-color: rgba(27, 27, 29, 1);
      }
      .react-datepicker__week-number--keyboard-selected {
        background-color: rgba(27, 27, 29, 1);
      }
      .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range),
      .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range),
      .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range),
      .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range) {
        background-color: rgba(246, 180, 12, 0.7);
      }
      .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
        min-width: 5rem;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
[data-theme-version="dark"] .form-control-popper-date-picker-range {
  .react-datepicker,
  .react-datepicker .react-datepicker__current-month,
  .react-datepicker .react-datepicker-year-header,
  .react-datepicker .react-datepicker__day-name,
  .react-datepicker .react-datepicker__day {
    color: #f6b40e;
  }
  .react-datepicker__month-text--disabled {
    color: #fff7db;
  }
  .react-datepicker__navigation-icon::before {
    border-color: #ccc;
  }
}
.custom-react-select-v2 {
  &.custom-react-select > div:nth-child(3) {
    height: 2.5rem;
    min-height: 2.5rem;
    // border-color: #141414 !important;
  }
  &.custom-react-select [class*="-indicatorContainer"] {
    padding: 7px;
  }
}
@media only screen and (max-width: 100rem) {
  .nav-control {
    transform: translateY(-50%);
  }
}
@media only screen and (min-width: 721px) {
  .menu-toggle .header .header-content,
  .header .header-content {
    height: 62px;
    background-color: var(--sidebar-bg);
    // width: calc(100% - 232px);
    // box-shadow: 0 3px 6px 0 rgba(206, 212, 218, 0.3);
  }
  // .nav-control {
  // 	transform: translateY(-50%);
  // }
  .nav-header {
    top: 0;
    height: 62px;
    width: 232px;
  }
}
@media only screen and (max-width: 720px) {
  .header .header-content,
  .menu-toggle .header .header-content {
    height: 62px;
    background-color: var(--sidebar-bg);
    width: calc(100%);
  }
  // .nav-control {
  // 	transform: translateY(-50%);
  // }
  .nav-header {
    top: 0;
    height: 62px;
    width: 232px;
  }
}
@media only screen and (max-width: 576px) {
  .menu-toggle .header .header-content,
  .header .header-content {
    height: 62px;
    background-color: var(--sidebar-bg);
  }
  .nav-header {
    top: 0;
    height: 62px;
    width: 100px;
  }
}

.nav-control {
  top: 46% !important;
  transform: translateY(-43%) !important;
  height: 45px !important;
  width: 45px !important;
}

.dlabnav.false {
  z-index: 4;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav {
  position: fixed;
  top: 62px;
}
[data-sidebar-style="overlay"] .dlabnav {
  box-shadow: 0 0.625rem 0.625rem -0.375rem rgba(0, 0, 0, 0.2);
}

.dlabnav {
  top: 62px;
  height: calc(100dvh - 62px);
  width: 232px;
  // .menu-scroll {
  // 	height: calc(100% - 62px);
  // }
  .dlabnav-scroll {
    overflow-y: auto;
  }
}

.form-height-38 {
  height: 2.5rem;
}

.form-height-32{
	height: 32px;
}


.content-body {
  // background-color: #f3f3f3;
}
[data-theme-version="dark"] {
  .custom-tab-1 .nav-link:hover,
  .custom-tab-1 .nav-link.active {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
  }
}
// .content-body .container-fluid{
//     padding-top: 2rem;
//     padding-right: 2rem;
//     padding-left: 2rem;
// }
.btn-reset-on-category:hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #1a1a1c;
}
[data-theme-version="dark"] .btn-reset-on-category {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
@media only screen and (min-width: 960px) {
  .btn-filter-on-category,
  .btn-reset-on-category {
    min-width: 200px;
  }
  .menu-scroll {
    min-height: calc(100dvh - 62px);
  }
  #menu.metismenu-bottom {
    margin-bottom: 32px;
  }
  .content-body .container-fluid {
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
@media only screen and (max-width: 720px) {
  .btn-filter-on-category,
  .btn-reset-on-category {
    min-width: 100px;
    padding: 10px 1rem;
  }
  .menu-scroll {
    min-height: calc(100dvh - 62px);
  }
  #menu.metismenu-bottom {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 576px) {
  .btn-filter-on-category,
  .btn-reset-on-category {
    min-width: 80px;
    padding: 0.6rem 1rem;
  }
  .menu-scroll {
    min-height: calc(100dvh - 62px);
  }
  #menu.metismenu-bottom {
    margin-bottom: 10px;
  }
}
.modal-content {
  border-radius: 0.3rem;
}

#main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}
.card {
  border-radius: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.05);
  .card-header {
    padding: 24px;
    padding-bottom: 20px;
    &.padding-normal {
      padding-bottom: 24px;
    }
  }
  .card-body {
    padding: 24px;
  }
}
@media only screen and (max-width: 650px) {
  .card {
    .card-header {
      padding: 18px;
      padding-bottom: 16px;
      &.padding-normal {
        padding-bottom: 18px;
      }
    }
    .card-body {
      padding: 18px;
    }
  }
}

.content-body {
  flex: 1;
}

.card-push-point {
  transition: all 0.3s ease;
  &:hover {
    background-color: #f6f7fa;
  }
}

.form-check-input-custom.form-check-input:checked[type="radio"] {
  background-image: none;
  background-color: #fff;
  border-color: #1a1a1c !important;
}
.form-check-input-custom.form-check-input[type="radio"] {
  border-color: #e1e1e1;
  background-color: #fafafa;
}
@media only screen and (max-width: 720px) {
  .form-check-input:checked[type="radio"]:after {
    left: 0px;
    top: 0px;
    transform: translate(30%, 30%);
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
  .authincation .login-aside {
    background-color: transparent;
    margin-top: 5dvh;
  }
}

@media only screen and (max-width: 576px) {
  .authincation .login-aside {
    background-color: transparent;
    margin-top: 7dvh;
  }
  .form-check-input:checked[type="radio"]:after {
    left: 0px;
    top: 0px;
    transform: translate(35%, 32%);
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
}

// Begin::Custom front-size
.dlabnav {
  .metismenu {
    padding-top: 1rem;
    & > li > a {
      padding: 14px 10px !important;
      .nav-text {
        font-size: 0.875rem !important;
      }
    }
    ul a {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .metismenu-bottom {
    & > li > a {
      padding: 10px 10px !important;
    }
  }
}

.page-titles {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 1.875rem;
  padding-left: 1.5rem;
  margin-top: 62px !important;
  min-height: 75px;
  & h2,
  & .h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--point);
    &.title-page-small {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
    }
    .text-sub-disabled {
      color: rgba(0, 0, 0, 0.38) !important;
    }
    &:has(.text-sub-disabled) > .text-sub-active {
      color: var(--point);
    }
  }
  & .breadcrumb li a {
    font-size: 0.875rem;
  }
}

[data-sidebar-style="overlay"][data-layout="vertical"] .header .page-titles {
  padding-left: 1.875rem;
}
@media only screen and (min-width: 1240px) {
  [data-sidebar-style="full"] .header,
  [data-sidebar-style="overlay"] .header {
    width: 100%;
    padding-left: 232px;
  }
}

@media only screen and (max-width: 650px) {
  .page-titles {
    min-height: 40px;
  }
}
.btn-search-category {
  max-height: 40px;
  max-width: 80px;
  font-size: 14px !important;
  padding: 0.45rem 1rem;
}
@media only screen and (max-width: 1440px) {
  .btn-search-category {
    max-height: 36px;
    padding: 8px 1rem !important;
  }
}

.form-label {
  font-size: 0.8rem;
}
.table.dataTable thead th,
.table.table-font-size.table-responsive thead th {
  font-size: 14px;
  background-color: #f8f9fb;
  font-weight: bold;
  color: #333;
}
.table.dataTable tbody td,
.table.table-font-size.table-responsive tbody td {
  font-size: 14px;
  color: #333;
}
.btn-font-size-vote {
  font-size: 14px;
}
.title-table-ranking {
  font-size: 20px;
}

@media only screen and (max-width: 650px) {
  .table.dataTable thead th,
  .table.table-font-size.table-responsive thead th {
    font-size: 13px;
  }
  .table.dataTable tbody td,
  .table.table-font-size.table-responsive tbody td {
    font-size: 13px;
  }
  .btn-font-size-vote {
    font-size: 13px;
  }
  .title-table-ranking {
    font-size: 18px;
  }
}
// .table-font-size.table-responsive tbody td{
// 	font-size: 0.94rem !important;
// 	color: var(--text-dark);
// }
// Begin::Custom front-size

.card-body {
  padding: 1rem;
}
table.dataTable > thead > tr > th {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

[data-headerbg="color_12"] .header:not(.home):after {
  background-color: var(--sidebar-bg);
}

.nav-control {
  left: 1rem;
  right: auto;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
  margin-left: 0rem;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav {
  left: -232px;
  width: 232px;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .header
  .page-titles {
  padding-left: 1.875rem;
}
[data-headerbg="color_12"] .header:not(.home):after {
  background-color: transparent;
}

[data-headerbg="color_12"] .header:not(.home) {
  background-color: transparent;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header {
  width: 232px;
  z-index: 5;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .brand-title {
  display: block;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .nav-header
  .brand-logo {
  padding-left: 2rem;
  padding-right: 0;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
  padding-left: 0rem;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .header
  .page-titles {
  padding-left: 1.5rem;
}
[data-nav-headerbg="color_12"] .nav-control {
  background: transparent;
}

[data-headerbg="color_12"] .hamburger .line {
  background: var(--point) !important;
  border-radius: 0px;
}
[data-headerbg="color_12"] .header-profile2 span {
  color: var(--primary);
}
[data-nav-headerbg="color_12"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(210, 210, 210, 0);
}
[data-nav-headerbg="color_12"] .header-right svg path {
  fill: rgb(187, 187, 187);
}
.notification_dropdown .dropdown-menu-end {
  min-width: 10rem;
  width: 10rem;
}
.nav-header,
.header-content,
.header.is-fixed .header-content {
  background-color: var(--sidebar-bg);
}

[data-theme-version="light"] .nav-header,
[data-theme-version="light"] .header-content,
[data-theme-version="light"] .header.is-fixed .header-content {
  box-shadow: 0 6px 6px -3px rgba(206, 212, 218, 0.3);
}
[data-sidebar-style="overlay"] .dlabnav .metismenu ul a {
  padding-top: 10px;
  padding-bottom: 10px;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .dlabnav
  .metismenu
  > li
  > a,
[data-sidebar-style="overlay"] .dlabnav .metismenu > li > a {
  border-radius: 10px;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .dlabnav
  .metismenu.metismenu-bottom
  > li:not(.mm-active)
  > a,
[data-sidebar-style="overlay"]
  .dlabnav
  .metismenu.metismenu-bottom
  > li:not(.mm-active)
  > a {
  border: 1px solid var(--warning);
  color: var(--warning);
}

[data-sidebar-style="full"][data-layout="vertical"]
  .dlabnav
  .metismenu
  > li.mm-active
  > a {
  border-radius: 10px;
  box-shadow: 0 6px 10px 0 rgba(57, 57, 57, 0.2);
}

[data-sidebar-style="full"][data-layout="vertical"]
  .dlabnav
  .metismenu.metismenu-bottom
  > li.mm-active
  > a,
[data-sidebar-style="overlay"]
  .dlabnav
  .metismenu.metismenu-bottom
  > li.mm-active
  > a {
  border: 1px solid var(--primary);
}
.nav-header .brand-logo {
  padding-left: 2rem;
  padding-right: 0;
}
@media only screen and (max-width: 750px) {
  .nav-header .brand-logo {
    padding-left: 0rem;
  }
}
@media only screen and (max-width: 576px) {
  .nav-header .brand-logo {
    padding-left: 30px;
    padding-right: 0;
  }
}

.nav-tabs-keyword {
  border-bottom: 1px solid #f6b40c;
  .nav-link {
    border-color: #f6b40c;
    color: #f6b40c;
    // border-radius: 0px;
    // margin: 0;
    border-bottom: 0px;

    &.active,
    &:hover {
      background-color: #f6b40c;
      color: #fff;
      // border-top-width: 2px;
      // border-top-color: #F6B40C;
    }
  }
}

.dlabnav .metismenu .has-arrow:after {
  border-top: 2px solid #c8c8c8;
  border-left: 2px solid #c8c8c8;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
  transform: rotate(-135deg) translateY(-50%);
}

.dlabnav .metismenu .mm-active > .has-arrow:after {
  border-top: 2px solid #c8c8c8;
  border-left: 2px solid #c8c8c8;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
  transform: rotate(45deg) translateY(0%);
}
.dlabnav .metismenu ul a:before {
  display: none;
}
.dlabnav .metismenu ul a.mm-active {
  font-weight: 700;
  color: var(--warning);
}

.hamburger .line:nth-child(3) {
  width: 1.375rem;
}

.apexcharts-text.apexcharts-xaxis-label tspan {
  fill: #030012;
  color: #030012;
}

.apexcharts-text.apexcharts-yaxis-label tspan {
  fill: #fb3335;
}

@media (min-width: 1400px) {
  .modal-xl {
    --bs-modal-width: 1300px;
    .container {
      max-width: 1240px;
    }
  }
}

//subscription
@media (min-width: 1100px) {
  .subscription-card {
    top: 0;
    position:relative;
    transition: all .3s ease;
    & .btn{
      padding-top: 13px !important;
      padding-bottom: 13px !important;
    }
    &:hover {
      top: -15px;
      // box-shadow: 5px 6px 6px 2px #e9ecef;
      // transform: scale(1.1);
    }
  }
}
.my-profile tbody  {
  & th{
    vertical-align: middle;
    background-color: #f7f9fb;
    width: 300px;
    padding: 16px 24px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.21;
  }
  & td{
    padding: 16px 24px;
    width: auto;
    font-weight: 500;
    font-size: 14px;
  }
  & tr:first-child th:only-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  & tr:last-child th:only-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.table-account-management{
  thead{
    & th{
      vertical-align: middle;
      background-color: #f7f9fb;
      width: 300px;
      padding: 16px 24px;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.21;
    }
  }
  tbody  {
    & td{
      padding: 16px 24px;
      width: auto;
      font-weight: 500;
      font-size: 14px;
    }
    & tr:first-child th:only-of-type {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    & tr:last-child th:only-of-type {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.stop-hover-button:hover {
  background-color: inherit !important; 
  color: inherit !important; 
}

.tag-keyword{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum';
    display: inline-block;
    height: auto;
    margin-right: 8px;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: default;
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.blur-container{
  position: relative;
  display: inline-block; /* Ensure container fits the content */
  .blur > * {
    filter: blur(6px);
    pointer-events: none; /* Disable interactions with blurred content */
  }
  &:has(.blur) table{
    min-height: 94px;
  }
  .blur-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0);
    color: var(--bs-secondary);
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.content-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.filter-warper{
  .filter-0{
    min-height: 64px;
  }
  .filter-1{
    height: 100%;
  }
  .filter-2{
    min-height: 64px;
  }
}
@media (max-width: 1200px){
  .filter-warper{
    flex-wrap: wrap;
    .filter-0{
      height: auto;
      width: 50%;
      justify-content: start;
      min-height: 25px;
      img{
        width: 20px;
        height: 20px;
      }
    }
    .filter-1{
      height: auto;
      order: 1;
      margin-top: 20px;
      width: auto!important;
      min-height: 25px;
    }
    .filter-2{
      height: auto;
      width: 50%;
      justify-content: end;
      min-height: 25px;
    }
  }
}

body{
  #modal-voting .modal-content{
    opacity: 1;
  }
  &:has(#modal-purchase){
    #modal-voting .modal-content{
      opacity: 0;
      transition: all .3s ease;
    }
  }
}

.keyword-search {
  .flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  .dropdown-menu {
    overflow: visible;
    box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
    animation-name: DropDownSlide;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    z-index: 1000;
    .dropdown-item {
      padding: 8px 15px;
      clear: both;
      font-weight: 400;
      text-align: inherit;
      white-space: nowrap;
      font-size: 14px;
      &.active, &:active {
        color: var(--primary);
        background: var(--rs-gray-100);
      }
    }
    
  }
  
  .name-prefix__control{
    border-radius: 0.5rem;
  }

  .form-control {
      height: 38px;
      padding-left: 40px;
      padding-right: 30px;
  }

  span.search-widget-icon {
      position: absolute;
      z-index: 10;
      font-size: 18px;
      line-height: 38px;
      left: 13px;
      top: 0;
      color: #000;
  }

  .search-widget-icon-close {
      right: 7px;
      left: auto !important;
  }

  ::-webkit-input-placeholder {
      /* Edge */
      color: #000;
  }

  :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #000;
  }

  ::placeholder {
      color: #000;
  }

  .keyword-wrapper {
      position: relative;
      &:hover {
          & > .dropdown-submenu {
              display: block;
          }
          .keyword-item {
              background-color: var(--rs-gray-100);
          }
      }
  }
  .keyword-wrapper__child {
      position: relative;
      &:hover {
          & > .dropdown-submenu__child {
              display: block;
          }
          & > .dropdown-submenu {
            display: block;
          }
          
          // .keyword-item__child {
          //     background-color: var(--rs-gray-100);
          // }
      }
  }

  .dropdown-submenu,
  .dropdown-submenu__child {
      left: 100% !important;
      top: 0% !important;
      transform: none !important;
      display: none;
      & .dropdown-item {
          min-height: 38px;
      }
  }

  .dropdown-menu:has(.result-search-menu) {
    display: block;
  }

  .dropdown-submenu:has(.result-search-sub) {
      display: block;
  }

  .dropdown-submenu__child:has(.result-search-sub__child) {
      display: block;
  }

  .drop {
      color: var(--vz-footer-color);
      font-size: 13px;
      margin-top: 2px;
      // font-style: italic;
  }

  .dropdown-menu .result-search-menu .dropdown-item:only-child,
  .dropdown-menu .result-search-sub .dropdown-item:only-child,
  .dropdown-menu .result-search-sub__child .dropdown-item:only-child {
      border-radius: 0.5rem;
  }
}

@media (max-width: 600px){
  .card-header-filter{
    flex-direction: column;
    align-items: flex-end;
    .div-w-100{
      width: 100%;
    }
    div:nth-child(1){
      width: 100%;
      & .div-col{
        width: auto;
        flex-grow: 1;
      }
    }
  }
}
.introduction-page{
  margin-top: 0px;
  .session-header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0px 1.875rem;
    background: #FFF;
    box-shadow: 0 6px 6px -3px rgba(206, 212, 218, 0.3);
    height: 62px;
    width: calc(100%);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1900px){
      padding: 0px 1.5rem;
    }
    @media (max-width: 800px){
      padding: 0px 1rem;
    }
    &-left{
      display: flex;
      align-items: center;
      gap: 25px;
      justify-content: flex-start;
      @media (max-width: 1900px){
        gap: 15px;
      }
        @media (max-width: 800px){
          gap: 10px;
      }
      &-navigate{
        height: 100%;
        display: flex;
        align-items: center;
        transform: translateY(1px);
      }
      &-logo{

      }
    }
    &-right{
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .session-intro{
    background: #000;
    overflow: hidden;
    padding: 120px 0px;
	  @media (max-width: 1900px){
		  padding: 60px 0px;
	  }
	  @media (max-width: 800px){
		  padding: 80px 0px;
	  }
    @media (max-width: 500px){
      padding: 80px 0px;
    }
	&.background{
		background-color: #000;
		background-image: url('../images/introduction/background_official.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
    @media (min-width: 2500px){
      background-image: url('../images/introduction/background_official_big.svg');
      background-size: inherit;
    }
    @media (max-width: 800px){
      background-image: url('../images/introduction/background_official_fold.svg');
    }
    @media (max-width: 500px){
      background-image: url('../images/introduction/background_official_mobile.svg');
    }
	}
    .title-intro{
      color: #FFF;
      text-align: center;
      font-size: 64px;
      font-style: normal;
      font-weight: 800;
      line-height: 130%;
      margin-top: 24px;
      @media (max-width: 1900px){
        font-size: 40px;
        font-style: normal;
        line-height: 48px;
      }
      @media (max-width: 800px){
        font-size: 32px;
        line-height: 150%;
      }
      @media (max-width: 500px){
        font-size: 32px;
        font-weight: 800;
        line-height: 130%;
      }
    }
    .list-icon-intro{
      width: 100%;
      margin: 64px 0px;
      display: flex;
      flex-wrap: nowrap;
      gap: 28px;
      padding-right: 28px;
      @media (max-width: 1900px){
        gap: 20px;
        margin: 40px 0px;
        padding-right: 20px;
      }
      @media (max-width: 800px){
        gap: 24px;
        margin: 56px 0px;
        padding-right: 24px;
      }
      @media (max-width: 800px){
        gap: 24px;
        margin: 56px 0px;
        padding-right: 24px;
      }
      .icon-intro{
          svg{
            height: 180px; 
            width: auto;
              @media (max-width: 1900px){
                height: 70px; 
                width: auto;
              }
              @media (max-width: 800px){
                height: 88px; 
                width: auto;
              }
              @media (max-width: 500px){
                height: 88px; 
                width: auto;
              }
          }
      }

    }
    .intro-content{
      color: #FFF;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 180%;

	  @media (max-width: 1900px){
		  font-size: 18px;
	  }
	  @media (max-width: 800px){
		  font-size: 16px;
      font-weight: 700;
	  }
    @media (max-width: 500px){
		  font-size: 16px;
      font-weight: 700;
	  }
    }
    .swiper-intro-element{
      .swiper {
        width: auto;
        height: 100%;
        padding: 0px 30px;
      }
      .swiper-wrapper {
        animation: slide 30s linear infinite; /* Adjust the duration as needed */
      }
      @keyframes slide {
        0% {
          transform: translateX(-100%);
        }
        100% {
          transform: translateX(0%);
        }
      }
      .swiper-slide {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        cursor: pointer;
        &:last-child{
          margin-right: 0px !important;
          @media (max-width: 800px){
            margin-right: 16px !important;
          }
          @media (max-width: 500px){
            margin-right: 16px !important;
          }
        }
      }
    }
  }
  .session-service{
    background: #FFF;
    overflow: hidden;
    padding: 120px 0px;
	  @media (max-width: 1900px){
		  padding: 60px 0px;
	  }
	  @media (max-width: 800px){
		  padding: 80px 0px;
	  }
    @media (max-width: 800px){
		  padding: 80px 0px;
	  }
    .content-service{
      color: #303038;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 190%;
      &-highlight{
        color: #F90;
      }
      @media (max-width: 1900px){
        font-size: 32px;
      }
      @media (max-width: 800px){
        font-size: 20px;
        font-weight: 800;
      }
      @media (max-width: 500px){
        font-size: 20px;
        font-weight: 800;
      }
    }
  }
  .session-influence{
    padding: 120px 0px;
    position: relative;
    overflow: hidden;
    background-color: #000;
    background-image: url('../images/introduction/background_service.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: 2500px){
      background-image: url('../images/introduction/background_service_big.svg');
      background-size: inherit;
    }
    @media (max-width: 1900px){
      padding: 60px 0px;
    }
    @media (max-width: 800px){
      background-image: url('../images/introduction/background_service_fold.svg');
      padding: 80px 0px;
      background-position: top;
    }
    @media (max-width: 500px){
      background-image: url('../images/introduction/background_service_mobile.svg');
      padding: 80px 0px;
      background-position: center;
    }
    .content-influence{
      z-index:2;
      overflow: hidden;
    .title-influence{
        text-align: center;
        color: #FFF;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        @media (max-width: 1900px){
          font-size: 38px;
        }
        @media (max-width: 800px){
          font-size: 24px;
          font-weight: 800;
          line-height: 160%;
          margin-bottom: 0px;
        }
        @media (max-width: 800px){
          font-size: 24px;
          font-weight: 800;
          line-height: 160%;
          margin-bottom: 0px;
        }
      }
      .swiper-influence{
          margin: 56px auto 0px;
          @media (max-width: 1900px){
            margin: 30px auto 0px;
          }
          @media (max-width: 800px){
            margin: 32px auto 0px;
          }
          @media (max-width: 500px){
            margin: 24px auto 0px;
          }
          .swiper {
            width: 100%;
            height: 100%;
            @media (max-width: 800px){
              width: auto;
            }
            @media (max-width: 500px){
              width: auto;
            }
            .swiper-wrapper{
              @media (max-width: 800px){
                justify-content: center;
              }
              @media (max-width: 500px){
                justify-content: flex-start;
              }
              
            }
          }
          .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
            border-radius: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:last-child{
              margin-right: 0px !important;
              @media (max-width: 800px){
                margin-right: 0px !important;
              }
              @media (max-width: 500px){
                margin-right: 0px !important;
              }
            }
            @media (max-width: 800px){
              border-radius: 16px;
            }
            @media (max-width: 500px){
              border-radius: 16px;
            }
          }
          .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media (max-width: 800px){
              border-radius: 16px;
            }
            @media (max-width: 500px){
              border-radius: 16px;
            }
          }
          .swiper-slide {
            width: 60%;
            cursor: pointer;
            min-height: 300px;
            max-width: 980px;
            @media (max-width: 800px){
              width: 45%;
              min-height: auto;
              // max-width: 400px;
            }
            @media (max-width: 500px){
              width: 78%;
              min-height: auto;
              // max-width: 320px;
            }
          }
          .swiper-button{
            margin-top: 56px;
            @media (max-width: 1900px){
              margin-top: 30px;
              svg{
                  width: 40px;
                  height: 40px;
              }
            }
            @media (max-width: 800px){
              margin-top: 32px;
              display: none!important;
              svg{
                  width: 36px;
                  height: 36px;
              }
            }
            @media (max-width: 500px){
              margin-top: 24px;
              display: flex!important;
              svg{
                  width: 36px;
                  height: 36px;
              }
            }
            .swiper-button-custom{
              cursor: pointer;
              &:hover{
                svg{
                  rect{
                    fill: #3B3E46;
                  }
                  path{
                    stroke: #A3A9B8;
                  }
                }
              }
            }
          }
      }
    }
  }
  .session-social{
    padding: 120px 0px;
    overflow: hidden;
    background: radial-gradient(55.74% 49.08% at 20.76% 68.46%, rgba(205, 181, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), radial-gradient(53.93% 29.28% at 91.43% 32.47%, rgba(255, 210, 49, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #FFF;
    @media (max-width: 1900px){
		  padding: 60px 0px;
	  }
	  @media (max-width: 800px){
		  padding: 80px 0px;
	  }
    @media (max-width: 500px){
      padding: 80px 0px;
    }
    .h2-social{
        color: #303038;
        text-align: left;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        @media (max-width: 1900px){
          font-size: 36px;
        }
        @media (max-width: 800px){
          font-size: 24px;
          font-weight: 800;
          line-height: 150%;
        }
        @media (max-width: 500px){
          font-size: 24px;
          font-weight: 800;
          line-height: 150%;
        }
    }
    .content-social{
      color: #61616C;
      font-size: 20px;
      font-style: normal;
      text-align: left;
      font-weight: 400;
      line-height: 180%;
      @media (max-width: 1900px){
        font-size: 16px;
      }
      @media (max-width: 800px){
        font-size: 14px;
        font-weight: 700;
        line-height: 180%;
      }
      @media (max-width: 500px){
        font-size: 13px;
        font-weight: 700;
        line-height: 180%;
      }
    }
    .h1-social{
      color: #000;
      text-align: right;
      font-size: 120px;
      font-style: normal;
      font-weight: 800;
      line-height: 140%;
        @media (max-width: 1900px){
        font-size: 90px;
        } 
        @media (max-width: 800px){
          font-size: 54px;
          font-weight: 800;
          line-height: 140%;
        }
        @media (max-width: 500px){
          font-size: 48px;
          font-weight: 800;
          line-height: 140%;
          text-align: left;
          margin-bottom: 8px;
        }
    }
    .list-social{
      margin-top: 80px;
      // display: flex;
      // justify-content: center;
      // align-items: stretch;
      // gap: 40px;
        @media (max-width: 1900px){
          margin-top: 50px;
        }
        @media (max-width: 800px){
          margin-top: 40px;
          gap: 10px;
        }
        @media (max-width: 500px){
          margin-top: 40px;
          gap: 10px;
        }
      .item-social{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        img{
			.blur{
				filter: blur(25px);
				-webkit-filter: blur(25px);
			}
          @media (max-width: 1900px){
              width: 130px;
              height: 130px;
            }
            @media (max-width: 1420px){
              width: 100px;
              height: 100px;
            }
            @media (max-width: 800px){
              width: 100%;
              height: 100%;
              max-height: 85px;
            }
            @media (max-width: 500px){
              width: calc((100dvw - 30px - 38px) / 3);
              height: 100%;
              max-height: fit-content;
            }
        }
        &-info{
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 100px;
          @media (max-width: 800px){
            min-height: 100px;
			    }
          @media (max-width: 500px){
            min-height: 80px;
          }
          &-text{
            color: #61616C;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
            margin-top: 12px;
            @media (max-width: 1900px){
              font-size: 16px;
            }
            @media (max-width: 800px){
              font-size: 14px;
              // font-weight: 800;
            }
            @media (max-width: 500px){
              font-size: 14px;
              // font-weight: 800;
            }
          }
          &-case{
            color: #303038;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            margin: 4px auto;
            letter-spacing : -2%;
            @media (max-width: 1900px){
              font-size: 20px;
              }
            @media (max-width: 800px){
            font-size: 16px;
            font-weight: 800;
            }
            @media (max-width: 500px){
              font-size: 14px;
              font-weight: 800;
            }
          }
          &-rank{
            color: #787880;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            @media (max-width: 1900px){
              font-size: 13px;
              }
            @media (max-width: 800px){
              font-size: 13px;
              // font-weight: 800;
            }
            @media (max-width: 500px){
              font-size: 12px;
              // font-weight: 800;
            }
          }
        &-other{
            color: #303038;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; 
            @media (max-width: 800px){
              font-size: 14px;
              font-weight: 800;
            }
            @media (max-width: 500px){
              font-size: 14px;
              font-weight: 800;
            }
          }
        }
      }
      .item-swiper-slide:nth-child(5){
        .item-social{
          img{
            @media (max-width: 1900px){
              width: auto;
               height: 130px;
             }
             @media (max-width: 1420px){
               width: auto;
               height: 100px;
             }
             @media (max-width: 800px){
				//   width: auto;
				//   height: 100%;
				//   max-height: 85px;
				width: calc(100% - 10px);
				height: 100%;
				max-height: 90px;
             }
             @media (max-width: 500px){
               width: calc(((100dvw - 30px - 11px) / 3) * 2);
               height: 100%;
               max-height: fit-content;
             }
         }
        }
        
      }
      .swiper-social{
          text-align: center;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: auto;
          // gap: 11px;
          flex-wrap: wrap;
          @media (max-width: 800px){
            // gap: 0px;
          }
          @media (max-width: 500px){
            // gap: 0;
          }
          .item-swiper-slide{
            flex: 1/5;
            box-sizing: border-box;
            @media (max-width: 800px){
              flex-wrap:nowrap;
              overflow-x: auto;
              width: calc(((90% / 6)* 1));
              min-width: 110px;
            }
            @media (max-width: 500px){
              margin-bottom: 24px; 
              width: auto;
              min-width:auto;
              &:nth-child(4), &:nth-child(5) {
                margin-bottom: 0px; 
              }
            }
          }
          .item-swiper-slide:nth-child(5) {
              flex: 2/5;
              @media (max-width: 800px){
                width: calc(((90% / 6)* 2));
                min-width: 110px;
              }
              @media (max-width: 500px){
                  width: auto;
                  min-width:auto;
              }
          }
        
        // .swiper {
        //   width: 100%;
        //   height: 100%;
        // }
        // .swiper-slide {
        //   text-align: center;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   width: auto;
        //   cursor: pointer;
        //   &:last-child{
        //     margin-right: 0px !important;
        //     @media (max-width: 800px){
        //       margin-right: 16px !important;
        //     }
        //     @media (max-width: 500px){
        //       margin-right: 16px !important;
        //     }
        //   }
        // }
        // .swiper-wrapper{
        //   justify-content: space-between;
        // }
      }
    }
  }
  .session-key-element{
    padding: 120px 0px;
    overflow: hidden;
    background: #ECEEF4;
	  @media (max-width: 1900px){
		padding: 60px 0px;
	  }
	  @media (max-width: 800px){
		  padding: 80px 0px;
	  }
    @media (max-width: 500px){
		  padding: 80px 0px;
	  }
    .title-key-element{
      color: #3E3F45;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      text-align: left;
      margin-bottom: 0;
      @media (max-width: 1900px){
      font-size: 40px;
      }
      @media (max-width: 800px){
        font-size: 24px;
        font-weight: 800;
      }
      @media (max-width: 500px){
        font-size: 24px;
        font-weight: 800;
      }
    }
    .swiper-key-element{
      margin: 32px auto 0px;
      @media (max-width: 1900px){
        margin: 25px auto 0px;
        }
      @media (max-width: 800px){
        margin: 24px auto 0px;
      }
      @media (max-width: 500px){
        margin: 24px auto 0px;
        }
      .swiper {
        width: 100%;
        height: 100%;
        padding: 0px 1rem;
      }
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        border-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .swiper-slide {
        width: 20%;
        border-radius: 24px;
        background: #FFF;
        padding: 8px 8px 32px 8px;
        box-shadow: 0px 0px 32px 0px rgba(192, 201, 224, 0.24);
        min-width: 310px;
        max-width: 310px;
        @media (max-width: 1900px){
          min-width: 260px;
          }
        @media (max-width: 800px){
            min-width: 240px;
            padding: 8px 8px 24px 8px;
          }
      }
      // @media (min-width: 1800px){
      //   .swiper-slide:nth-child(7) {
      //     opacity: 0;
      //     width: calc(((100% - 1220px) / 2) - 155px)!important;
      //   }
      // }
      
      .key-element{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        @media (max-width: 800px){
          gap: 16px;
        }
        .svg-element svg{
          width: 100%;
          height: auto;
        }
        &-info{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          &-title{
            color: #342E2E;
            text-align: center;
            font-size: 20px;
            font-style: normal; 
            font-weight: 800;
            line-height: 160%;
            margin-bottom: 8px;
            @media (max-width: 800px){
              font-size: 18px;
              font-weight: 600;
            }
          }
          &-content{
            color: #787880;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            li{
              margin-bottom: 4px;
              list-style: disc;
              text-align: start;
              font-size: 16px; 
              font-style: normal;
              font-weight: 700;
              line-height: 150%;
              @media (max-width: 800px){
                font-weight: 400;
              }
            }
          }
        }
      }
    }
    .swiper-button{
      .swiper-button-custom{
        cursor: pointer;
        svg{
          @media (max-width: 1900px){
            width: 40px;
            height: 40px;
          }
          @media (max-width: 800px){
            width: 36px;
            height: 36px;
          }
        }
        &:hover{
          svg{
            rect{
              fill: #808694;
            }
            path{
              stroke: #F4F6FC;
            }
          }
        }
        &.swiper-button-lock {
          display: block;
        }
      }
    }
  }
  .session-rank-index{
    padding: 100px 0px;
    overflow: hidden;
    background: #FFF;
	  @media (max-width: 1900px){
		padding: 60px 0px;
	  }
	  @media (max-width: 800px){
		padding: 80px 0px;
	  }
    @media (max-width: 500px){
      padding: 80px 0px;
    }
    .title-rank-index{
      color: #3C3D43;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      margin-bottom: 0;
      @media (max-width: 1900px){
      font-size: 40px;
      }
      @media (max-width: 800px){
        font-size: 24px;
        font-weight: 800;
      }
      @media (max-width: 800px){
        font-size: 24px;
        font-weight: 800;
      }
    }
    .content-rank-index{
      color: #61616C;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%;
      margin-bottom: 56px;
      @media (max-width: 1900px){
      font-size: 15px;
      margin-bottom: 35px;
      }
      @media (max-width: 800px){
        font-size: 16px;
        margin-bottom: 48px;
	    }
      @media (max-width: 800px){
        font-size: 16px;
        margin-bottom: 48px;
      }
    }
    .list-rank-index{
      .tab-rank-index{
        margin-bottom: 40px;
        overflow: hidden;
        @media (max-width: 1900px){
        margin-bottom: 35px;
        }
        @media (max-width: 800px){
        margin-bottom: 24px;
        }
        @media (max-width: 500px){
          margin-bottom: 24px;
          }
      }
      .item-rank-index{
        img{
          width: 100%;
          height: auto;
          min-height: 277px;
          background-color: #f5f8fd;
		      border-radius: 16px;
          @media (max-width: 800px){
            min-height: 200px;
            max-height: 300px
          }
          @media (max-width: 500px){
            min-height: 150px;
          }
        }
        .item-rank-index-title{
          color: #303038;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 180%; 
          @media (max-width: 1900px){
            font-size: 20px;
          }
          @media (max-width: 800px){
            font-size: 20px;
            margin-top: 24px;
            margin-bottom: 0px;
            font-weight: 800;
          }
          @media (max-width: 500px){
            font-size: 20px;
            margin-top: 24px;
            margin-bottom: 0px;
            font-weight: 800;
          }
      }
      .item-rank-index-content{
          color: #61616C;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 180%;
          margin-bottom: 0px;
          @media (max-width: 1900px){
          font-size: 16px;
          }
          @media (max-width: 800px){
            font-size: 16px;
          }
          @media (max-width: 500px){
            font-size: 16px;
          }
          }
      }
    }
  }
  .session-footer{
    padding: 120px 0px;
    overflow: hidden;
    background-color: #000;
    background-image: url('../images/introduction/background.png');
    background-position: 90% 100%;
    background-repeat: no-repeat;
	  background-size: contain;
	  @media (max-width: 1900px){
		padding: 60px 0px;
	  }
	  @media (max-width: 800px){
      padding: 80px 0px 50px;
      background-position: 100% 0%;
      background-image: url('../images/introduction/background_footer_fold.svg');
	  }
    @media (max-width: 500px){
      padding: 80px 0px;
      background-position: 100% 0%;
      background-image: url('../images/introduction/background_footer_mobile.svg');
    }
    .title-footer{
      color: #FFF;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; 
      @media (max-width: 1900px){
      font-size: 40px;
      }
      @media (max-width: 800px){
      font-size: 28px;
      font-weight: 800;
      }
      @media (max-width: 500px){
        font-size: 28px;
        font-weight: 800;
      }
    }
    .content-sub-1-footer{
      color: #CAD2DC;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; 
      margin-bottom: 16px;
      @media (max-width: 1900px){
      font-size: 14px;
      }
      @media (max-width: 800px){
      font-size: 16px;
      }
      @media (max-width: 500px){
        font-size: 16px;
        }
    }
    .content-sub-2-footer{
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      text-align: left;
      font-weight: 500;
      line-height: 150%;
      margin-bottom: 32px;
      @media (max-width: 1900px){
      font-size: 14px;
      }
      @media (max-width: 800px){
        font-size: 16px;
        margin-bottom: 32px;
      }
      @media (max-width: 500px){
        font-size: 16px;
        margin-bottom: 32px;
        }
    }
  }
  .session-official{
	  padding: 120px 0px;
    overflow: hidden;
    background-color: #fff;
	  @media (max-width: 1900px){
		padding: 60px 0px;
	  }
    @media (max-width: 800px){
      padding: 80px 0px;
    }
    @media (max-width: 500px){
      padding: 80px 0px;
    }
	.title-official{
		color: #303038;
		text-align: center;
		font-size: 48px;
		font-style: normal;
		font-weight: 700;
		line-height: 150%; 
    margin-bottom: 0px;
	  @media (max-width: 1900px){
		 font-size: 36px;
     margin-bottom: 26px;
	  }
	  @media (max-width: 800px){
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 24px;
	  }
    @media (max-width: 500px){
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 24px;
    }
	}
	.list-item-introduction{
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 56px;
		align-self: stretch;
		@media (max-width: 1900px){
			gap: 40px;
		  }
		@media (max-width: 800px){
			gap: 24px;
		}
    @media (max-width: 500px){
			gap: 24px;
		}
		.item-introduction{
			border-radius: 16px;
			border: 1px solid #DEE3F2;
			background: #F6F7FA;
			padding: 40px;
      width: 100%;
			@media (max-width: 1900px){
				padding: 20px;
			  }
			@media (max-width: 800px){
				padding: 16px;
			}
			.item-introduction-left{
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 16px;
				padding: 24px;
				border-radius: 16px;
				background: #FFF;
        color: #303038;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 170%;
				@media (max-width: 1900px){
					padding: 16px;
          font-size: 16px;
				}
				@media (max-width: 800px){
					padding: 16px;
          font-size: 16px;
				}
        @media (max-width: 500px){
					padding: 16px;
          font-size: 16px;
				}
        .item-introduction-number{
          color: #303038;
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 150%;
          margin-left: 8px;
          @media (max-width: 800px){
            font-size: 18px;
          }
          @media (max-width: 500px){
            font-size: 18px;
          }
        }
			}
			.item-introduction-right{
				padding: 0px;
				svg{
					width: 100%;
					height: 100%;
				}
			}
		}
	}

  }
}

// @media (max-width: 1900px){
// 	.swiper-key-element{
// 	  .swiper-slide {
// 		width: 25%!important;
// 	  }
// 	}
//   }
//   @media (max-width: 1500px){
// 	.swiper-key-element{
// 	  .swiper-slide {
// 		width: 22%!important;
// 	  }
// 	}
//   }
//   @media (max-width: 1024px){
// 	.swiper-key-element{
// 	  .swiper-slide {
// 		width: 35%!important;
// 	  }
// 	}
//   }
// @media (max-width: 600px){
//   .swiper-key-element{
//     .swiper-slide {
//       width: 40%!important;
//     }
//   }
// }
.px-custom-v{
  @media (max-width: 800px){
    padding-left: 20px;
    padding-right: 20px;
  }
}

@keyframes slideAnim {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.looper {
  width: 100%;
  overflow: hidden;
}

.looper__innerList {
  display: flex;
  justify-content: center;
  width: fit-content;
}

.looper__innerList[data-animate="true"] .looper__listInstance {
  animation: slideAnim linear infinite;   
}

.looper__listInstance {
  display: flex;
  width: max-content;

  animation: none;
}

td.bg-secondary-100{
  background-color: #f8f9fb!important;
}